import React from 'react';
import Typed from 'react-typed';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';
import ScrollAnimation from "react-animate-on-scroll";

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                    <div className="row row--30">
                        <div className="col-lg-6">
                            <div className="mission-title">
                                <p style={{ fontSize: 19 }} className="title">"At the forefront of Training, Recruitment and Talent Acquisition, we stand as a trusted partner to an array of employers. Our consultative approach involves a deep understanding of your business and talent requirements, enabling us to offer bespoke services aligned with your needs. Our team of skilled recruiters is unwavering in their commitment to providing our clients with top-tier talent, ensuring a seamless fit for their organizational goals." <br /> {" "}</p>

                            </div>
                        </div>
                        <div className="col-lg-6 mt_md--30 mt_sm--30">
                            <AccordionOne customStyle="" />
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    )
}
export default mission;
