// Assuming you have a root reducer that combines navReducer
import { createStore, combineReducers } from 'redux';
import navReducer from './nav/navreducer';
import authReducer from './Auth/authReducer';


export const rootReducer = combineReducers({
  nav: navReducer,
  user: authReducer,
});

export const store = createStore(rootReducer);
