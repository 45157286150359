import React, { createContext, useContext, useEffect, useState } from 'react';

const NavContext = createContext();

export const NavProvider = ({ children }) => {
  // Check localStorage for an existing selectedMenu value
  const initialSelectedMenu = localStorage.getItem('selectedMenu') || 'Home';
  const [selectedMenu, setSelectedMenu] = useState(initialSelectedMenu);

  const selectMenu = (menu) => {
    setSelectedMenu(menu);
    localStorage.setItem('selectedMenu', menu);
  };

  // Add useEffect to update selectedMenu when localStorage changes
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSelectedMenu = localStorage.getItem('selectedMenu') || 'Home';
      setSelectedMenu(updatedSelectedMenu);
    };

    // Listen for changes to localStorage
    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <NavContext.Provider value={{ selectedMenu, selectMenu }}>
      {children}
    </NavContext.Provider>
  );
};

export const useNav = () => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error('useNav must be used within a NavProvider');
  }
  return context;
};
