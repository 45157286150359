// RequirmentForm.js
import React, { useState } from 'react';
import { BASE_URL } from '../../common/config/endpoints';

const RequirmentForm = ({ role }) => {
  const [result, showResult] = useState({ success: false, error: false });

  const [formData, setFormData] = useState({
    role: role,
    fullname: '',
    last_name: '',
    email: '',
    mobile: '',
    message: '',
    resume: null,
    type: 'test',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'resume' ? files[0] : value,
    }));

  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (key === 'resume') {
          formDataToSend.append(key, value, value.name);
        } else {
          formDataToSend.append(key, value);
        }
      });


      let apiEndpoint;
      let apiFormData;

      if (role === 'training') {
        apiEndpoint = `${BASE_URL}/training/register`;
        apiFormData = {
          name: formData.fullname + ' ' + formData.last_name,
          email: formData.email,
          phone: formData.mobile,
          type: formData.type,
          message: formData.message,
        };
      } else if (role === 'recruiter' || role === 'candidate') {
        apiEndpoint = role === 'recruiter' ? `${BASE_URL}/employer/register` : `${BASE_URL}/candidate/register`;
        apiFormData = {
          first_name: formData.fullname,
          last_name: formData.last_name,
          email: formData.email,
          phone: formData.mobile,
          message: formData.message,
        };

        if (role === 'candidate') {
          apiFormData.file = formData.resume;
        }
      }

      const apiResponse = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          redirect: 'follow'
        },
        body: formDataToSend,
      });

      const apiResponseData = await apiResponse.json();
      console.log('API Response:', apiResponseData);

      if (apiResponseData.success) {
        showResult({ success: true, error: false });
      } else if (apiResponseData.message === 'Validation Error.') {
        // Extract validation errors from the response data
        const validationErrors = apiResponseData.data;

        // Display the specific validation error messages for each input
        showResult({
          success: false,
          error: true,
          validationErrors: validationErrors,
        });
      } else {
        showResult({ success: false, error: true });
      }
    } catch (error) {
      console.error('API Request Failed:', error);
      showResult({ success: false, error: true });
    }

    e.target.reset();

    setTimeout(() => {
      showResult({ success: false, error: false });
    }, 5000);
  };

  return (
    <div className=''>
      <form className="your-form-class" onSubmit={submitForm}>
        <div className="form-group">
          {result.success && (
            <p className="text-success">Your message has been successfully sent. I will contact you soon.</p>
          )}
          {result.error && !result.validationErrors && (
            <p className="text-danger">There was an error sending your message. Please try again later.</p>
          )}
          {result.validationErrors && (
            <>
              {result.validationErrors.email && (
                <p className="text-danger">{result.validationErrors.email[0]}</p>
              )}
              {result.validationErrors.phone && (
                <p className="text-danger">{result.validationErrors.phone[0]}</p>
              )}
              {/* Add more blocks for other input fields if needed */}
            </>
          )}
        </div>

        <div className='row'>
          <div className="form-group col">
            <label htmlFor="fullname">First Name:</label>
            <input
              type="text"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group col">
            <label htmlFor="last_name">Last Name:</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='row'>
          <div className="form-group col">
            <label htmlFor="email">Email Address:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group col">
            <label htmlFor="mobile">Mobile Number:</label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className='row'>
          <div className="form-group col">
            <label htmlFor="message">Your Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

        </div>

        <div className='row'>
          {role === 'candidate' && (
            <div className="form-group col">
              <label htmlFor="resume">Upload Resume:</label>
              <input
                type="file"
                id="resume"
                name="resume"
                accept=".pdf,.doc,.docx"
                onChange={handleChange}
                required
                multiple={false}
              />
            </div>
          )}

          {role === 'training' && (
            <div className="form-group col">
              <label htmlFor="type">Type:</label>
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
                style={{ border: "2px solid #00000024", background: "transparent", padding: 20 }}
              >
                <option value="">Select a Training Type</option>
                <option value="Onboarding Training">Onboarding Training</option>
                <option value="Human Resources Fundamentals">Human Resources Fundamentals</option>
                <option value="Communication and Interpersonal Skills">Communication and Interpersonal Skills</option>
                <option value="Recruitment and Staffing">Recruitment and Staffing</option>
                <option value="Employee Relations">Employee Relations</option>
                <option value="Training and Development">Training and Development</option>
                <option value="Performance Management">Performance Management</option>
                <option value="Ethics and Professionalism">Ethics and Professionalism</option>
                <option value="Health and Safety">Health and Safety</option>
                <option value="Conflict Resolution and Problem-Solving">Conflict Resolution and Problem-Solving</option>
                <option value="Team Building and Employee Engagement">Team Building and Employee Engagement</option>
              </select>
            </div>
          )}
        </div>

        <div className="form-group">
          <button className="btn-default btn-large" type="submit">
            Submit Now
          </button>
        </div>
      </form>
    </div>
  );
};

export default RequirmentForm;
