import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setUserDetails } from '../../redux/Auth/authActions';
import { useHistory } from 'react-router-dom';

const Result = ({ success, message }) => {
    return (
        <p className={success ? "success-message" : "error-message"} style={{ color: success ? "green" : "red" }}>{message}</p>
    );
}

function SignupForm({ setUserDetails }) {
    const history = useHistory();

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
    });

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState({ success: false, message: '' });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true);

        const userInputs = {
            first_name: formData.firstname,
            last_name: formData.lastname,
            email: formData.email,
            phone: formData.phone,
            password: formData.password,
        };

        try {
            const response = await fetch('https://tactissadmin.frontendsourcecode.com/api/v1/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userInputs),
            });

            const responseData = await response.json();

            setResult({
                success: response.ok,
                message: responseData.message,
            });

            if (response.ok) {
                // If registration is successful and user is verified, redirect to OTP screen
                if (responseData.success && responseData.data.is_verified) {
                    setUserDetails(responseData);
                    history.push("/otp");
                }
            }
        } catch (error) {
            console.error('Error during API call:', error);
            setResult({
                success: false,
                message: 'Failed to register user. Please try again.',
            });
        }

        setLoading(false);
    };

    return (
        <form className={`container`} action="" onSubmit={sendEmail} style={{ maxWidth: '600px', margin: 'auto' }}>
            <div className="form-group">
                <input
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    
                    onChange={handleChange}
                    
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    
                    onChange={handleChange}
                   
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    
                    onChange={handleChange}
                    
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    
                    onChange={handleChange}
                    
                />
            </div>

            <div className="form-group">
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    
                    onChange={handleChange}
                   
                />
            </div>

            <div className="form-group">
                <button className="btn-default btn-large" style={{ width: "100%" }} disabled={loading}>
                    {loading ? 'Loading...' : 'Register'}
                </button>
            </div>

            <div className="form-group">
                {result.message && <Result success={result.success} message={result.message} />}
            </div>
        </form>
    );
}

const mapStateToProps = (state) => ({
    user: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    setUserDetails: (userDetails) => dispatch(setUserDetails(userDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
