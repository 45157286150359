import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/Header';
import FooterTwo from '../common/footer/FooterTwo';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import About from '../elements/about/About';
import OurExpertises from "../elements/about/OurExpertises";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">

                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-white" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-650 bg_image"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/about/Aboutus_hero.png)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* <Separator /> */}

                {/* Start Service Area  */}
                <About />
                {/* End Service Area  */}


                <div className="rwt-elements-area rn-section-gap" style={{padding:'30px 0 !important'}}>
                        <div className="container">
                            <div className="row mb--20">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Tactiss"
                                        title = "Our expertise."
                                    />
                                </div>
                            </div>
                            <OurExpertises column="col-lg-12 mt--30" teamStyle="" />
                        </div>
                    </div>


                {/* Start Service Area  */}
               
                {/* End Service Area  */}



                

                <Separator />

    


                <FooterTwo />
            </main>
        </>
    )
}

export default AboutUs;
