// reducers.js
import { SELECT_MENU } from './navaction';

const initialState = {
  selectedMenu: 'Home', // Default selected menu
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_MENU:
      return {
        ...state,
        selectedMenu: action.payload,
      };
    default:
      return state;
  }
};

export default menuReducer;
