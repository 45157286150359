import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";



import SignInForm from './SignInForm';

const SignIn = () => {
    return (
        <>
            <SEO title="SignIn || Tactiss" />
            <Layout>
           <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:10}}>
           <h1 className="title theme-gradient h2" dangerouslySetInnerHTML={{__html: "SignIn"}}></h1>
           </div>
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area ">
                        <div className="container">
                           
                            <SignInForm />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}



export default SignIn;

