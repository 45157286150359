import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({ customStyle }) => {

    const benefits = [
        {
            heading: 'Proven Track Record:',
            description:
                'Benefit from our successful placements and consultative recruitment approach that has earned accolades from clients.',
        },
        {
            heading: 'Client-Centric Focus:',
            description:
                'Experience open communication, regular updates, and a commitment to understanding and meeting your evolving needs.',
        },
        {
            heading: 'Innovation and Excellence:',
            description:
                'Stay ahead with innovative solutions and excellence ingrained in every aspect of our services.',
        },
    ];

    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        What is Tactiss? How does it work?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <ul>
                            <li>At Tactiss HR, our Recruitment Consultants specialize in comprehensive talent mapping within the market.</li>
                            <li>Once we grasp your specific hiring needs, our dedicated team will promptly engage with you to propel the recruitment process forward, ensuring a tailored and efficient approach to finding the ideal candidates for your organization.</li>

                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>


            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        Our Recruitment Process:
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>
                        We adhere to a comprehensive recruitment process encompassing:

                        <ul>
                            <li>Initial Consultation: A thorough discussion to understand your hiring needs.</li>
                            <li>Customized Talent Mapping: Utilizing tailored strategies to identify the right candidates.</li>
                            <li>Interviews and Assessments: Rigorous evaluation to ensure the best fit for your requirements.</li>
                            <li>Regular Updates and Feedback: Providing consistent communication and feedback throughout the hiring process.</li>
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        Why Choose Tactiss?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>
                        <div>
                            {benefits.map((benefit, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    <h5 style={{ marginBottom: '5px' }}>{benefit.heading} </h5>
                                    <p>{benefit.description}</p>
                                </div>
                            ))}
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    );
}

export default AccordionOne;
