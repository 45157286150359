import React from 'react';
import Typed from 'react-typed';
import ScrollAnimation from "react-animate-on-scroll";

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/home/tagline.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                        <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                            <div className="section-title">
                                <h2 className="title">Tactiss: <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Journey Towards HR Excellence",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>TACTISS HR, a prominent recruitment outsourcing and consulting organization with a focus on skill training, is based in Bangalore. Our dedicated team of seasoned professionals, with extensive experience serving leading organizations nationwide, manages the company. Since our inception, TACTISS HR has been instrumental in assisting numerous clients in finding the right talent with the required skills and has successfully helped over 1200 candidates secure their dream jobs.
                                </p>
                                <p>Operating across various industry verticals and functional areas, we are recognized for delivering fast, efficient, and compassionate services to both clients and candidates. Tactiss HR has garnered accolades and business from existing and new clients, owing to our consultative recruitment approach. Our core objective is to assist clients in enhancing their recruitment processes, focusing on key metrics such as Quality-of-Hire, Time-to-Fill, and Cost-per-Hire.
                                </p>
                                <p>
                                We specialize in providing tailor-made hiring solutions to our clients, promoting greater efficiency and business performance. Through a collaborative approach and flawless execution, we aid clients in achieving their strategic talent acquisition goals. Our distinctive qualities of passion, integrity, and strong work ethics set us apart, making us the preferred hiring partner for an ever-expanding client base.
                                </p>
                                <p>At TACTISS HR, our commitment is to deliver excellent value to both clients and candidates through highly professional services. As recruiters, we take pride in being the driving force for the "Journey towards HR excellence."</p>
                                {/* <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div> */}
                            </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutOne
