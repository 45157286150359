// authReducer.js
const localStorageKey = 'userDetails';

const initialState = {
    ...JSON.parse(localStorage.getItem(localStorageKey)) || {},
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_DETAILS':
            const newState = {
                ...state,
                ...action.payload,
            };

            // Save the updated user details to local storage
            localStorage.setItem(localStorageKey, JSON.stringify(newState));

            return newState;
        default:
            return state;
    }
};

export default authReducer;
