import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'


import Home from "./pages/Home";

import AboutUs from "./pages/AboutUs";

import Contact from "./elements/contact/Contact";
import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';
import Jobs from './components/blog/Jobs';
import SignIn from './elements/auth/SignIn';
import SignUp from './elements/auth/SignUp';


// context 
import { NavProvider } from './context/NavContext';
import Requirment from './elements/auth/Requirment';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import OTP from './elements/auth/OTP';
import JobDetails from './components/blog/JobDetails';

const App = () => {
    return (
        <Router>
            <Provider store={store}>
             <NavProvider>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
                    <Route path={`${process.env.PUBLIC_URL + "/jobs"}`} exact component={Jobs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/jobdetails"}`} exact component={JobDetails}/>

            
                    {/* Auth  */}
                    <Route path={`${process.env.PUBLIC_URL + "/signin"}`} exact component={SignIn}/>
                    <Route path={`${process.env.PUBLIC_URL + "/signup"}`} exact component={SignUp}/>
                    <Route path={`${process.env.PUBLIC_URL + "/recruitment"}`} exact component={Requirment}/>
                    <Route path={`${process.env.PUBLIC_URL + "/otp"}`} exact component={OTP}/>
     
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                   
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>

                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>
                    
                </Switch>
            </PageScrollTop>
            </NavProvider>
            </Provider>
        </Router>
    )
}


export default App
