import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "Empowering Tomorrow:  Pioneering Women-Centric HR Consultancy",
        description: " \
        In a world that is progressively recognizing the importance of diversity and inclusivity, \
        the emergence of women-based HR consultancy startups has become a beacon of empowerment and change. \
        TACTISS, a trailblazing venture, stands at the forefront of this movement, \
        committed to revolutionizing the workplace landscape by placing women at the heart of human resources.",
        name: "Nandhini G",
        subtitle: "Director",
        image: "testimonial-dark-01"
    },
];


const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form" style={{fontWeight:"bold",color:"black"}}>{data.form}</span>
                                        <p className="description" style={{fontSize:18}}>{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    {/* <div className="thumbnail"> */}
                                        <img style={{maxWidth: "100%",
    height: "auto"}} className="w-100" src={'./images/home/hero-img.jpg'} alt="Tactiss" />
                                    {/* </div> */}
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















