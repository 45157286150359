import React from 'react';
import SEO from "../common/SEO";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';

import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";

import SlipThree from '../elements/split/SlipThree';
import TimelineOne from "../elements/timeline/TimelineOne";
import Header from '../common/header/Header';
import Intro from '../components/intro/Intro';






const Home = () => {
    return (
        <>
            <SEO title="Home" />
            <main className="page-wrapper">
            <Header btnStyle="btn-small" HeaderSTyle="header-transparent" />
            
               
                  <div className="container">
                        <div className="row">
                            <div className="col-lg-12"></div>
                <Intro />
               
                </div>
                </div>
                {/* End Slider Area  */}

                <Separator />





                {/* Start About Area  */}
                <AboutOne />
                {/* End About Area  */}


                {/* Start Elements Area  */}
                <div className="rwt-split-area">
                    <div className="wrapper">
                        <SlipThree />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />
                {/* Start Service Area  */}
               



                <Separator />
                {/* Start Mission Area   */}
                <Mission />
                {/* Start Mission Area  */}




                <Separator />



                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Working Process"
                                    title=""
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />
                {/* Start Testimonial Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Tactiss HR"
                                    title="Few words by our CEO"
                                    description="."
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div>
                {/* End Testimonial Area  */}

                
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default Home;
