import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setUserDetails } from '../../redux/Auth/authActions';
import { useHistory } from 'react-router-dom';

function SigninForm({ setUserDetails, setRegistrationSuccess }) {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [resultMessage, setResultMessage] = useState('');

    const loginUser = async (email, password) => {

     
        try {
            const response = await fetch('https://tactissadmin.frontendsourcecode.com/api/v1/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData.success) {
                    // console.log('User logged in successfully.');

                    // Dispatch action to store email and otp
                    setUserDetails({
                      "success": true,
                      "data": {
                          "user": {
                              "id": 6,
                              "first_name": "Soumya",
                              "last_name": "Nayak",
                              "email": "soumya050794@gmail.com",
                              "phone": null,
                              "email_verified_at": null,
                              "otp": "5152",
                              "dob": null,
                              "gender": null,
                              "country_id": null,
                              "state_id": null,
                              "city_id": null,
                              "is_active": true,
                              "is_verified": true,
                              "owner_id": 2,
                              "owner_type": "App\\Models\\Candidate",
                              "language": "en",
                              "profile_views": "0",
                              "theme_mode": "0",
                              "created_at": "2024-01-14T15:34:57.000000Z",
                              "updated_at": "2024-01-14T15:35:00.000000Z",
                              "facebook_url": null,
                              "twitter_url": null,
                              "linkedin_url": null,
                              "google_plus_url": null,
                              "pinterest_url": null,
                              "is_default": false,
                              "stripe_id": null,
                              "region_code": null,
                              "full_name": "Soumya Nayak",
                              "avatar": "https://tactissadmin.frontendsourcecode.com/assets/img/default-logo.png",
                              "country_name": null,
                              "state_name": null,
                              "city_name": null,
                              "media": [],
                              "country": null,
                              "city": null,
                              "state": null
                          },
                          "token_type": "Bearer",
                          "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiN2U2NjU0ZmViMzE5ZTRjZDY0YTViZjI0NDIxMmU3MGNlY2ZiY2E3ZmQxMGI2NjcyNTYzYTkyMGJhYmJjOTk2YTQ1MjNkMWQyNTE5MDI5NGIiLCJpYXQiOjE3MDUyNTU1MDcuODc1ODE4LCJuYmYiOjE3MDUyNTU1MDcuODc1ODIsImV4cCI6MTczNjg3NzkwNy44NzM1NzUsInN1YiI6IjYiLCJzY29wZXMiOltdfQ.hP_9vZ_KyyoFLXEEJ0aA7lkt7OrOv499stLGdeYaam8z0F-_lRk3TD9JTICoDDCglxzEpiQW6CNXKWj_c289CTNO-yYnx5kiz8zFrEFEswaPz7pDsVB4hISXjSmJWnK7_JNb6h1XE_ILJYc2WAdHj-jQw452kZrdTP0EgGvdAxjd4Go1PVfi-41z07KDi0ser7OfIeGbwY-ju9XMbNKlX0pzudhisFfU-WIao6qg4TLP4zRdN2gDssmpcQHjpurS6IYsbMMjgX8KoCsbgDmfagq36yd1gO7dUtC7WaOWVQrDe-LKp4Bck2oEBENKpEUVEkAHAtg_g4prPUDmlvJVLAzE41LZT5KXePW5WS780S4uJHFaDWJuJ2nIkpOFLpw_JkjZDAc9OI9EerlyMNdXLwvqzs71eRZVKVeFkMq9zN-YbV2VfB1K_WUH9wAtb6v17Fqa73oqao_DS3jU7fF9Jz_dvxwm4g7Lo38tqWbl-UrfNOiklObssIOE4m8wnOyMyi2mrF4MxVC1AIf5J9wgwL8Wbd_CIZv9pZNvcBICaP55voZa8JXiFoCvgMzMSW0auKqCPZG3TDEt7aMbJfkzQOUrUK8SG1EOxbD-AhaR2k8QGQY7IWoCK2txW85HLPVkIQgqTFilOys4pAn9RbzTZD40R3YKkj7fCeEhrmCNaxY"
                      },
                      "message": "User login successfully."
                  });

                    // Set registration success to true
                    setRegistrationSuccess(true);

                    if(responseData.data.user.is_verified === true){
                      history.push('/otp');
                    }
                   

                    // Do not show result message in this case
                    return;
                } else {
                    console.error('Failed to log in:', responseData.message);
                    setRegistrationSuccess(false);
                    setResultMessage(responseData.message);
                }
            } else {
                console.error('Failed to log in.');
                setRegistrationSuccess(false);
                setResultMessage('Failed to log in. Please try again.');
            }
        } catch (error) {
            console.error('Error during API call:', error);
            setRegistrationSuccess(false);
            setResultMessage('Error during API call. Please try again.');
        }

        setLoading(false);

        // Show the result message
        setShowResult(true);

        // Clear the result message after 5 seconds
        setTimeout(() => {
            setShowResult(false);
            setResultMessage('');
        }, 5000);
    };

    const handleSignIn = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const email = formData.get('email');
        const password = formData.get('password');

        setLoading(true);

        // Call loginUser function
        await loginUser(email, password);
    };

    return (
        <div>
            <form className={`container`} action="" onSubmit={handleSignIn} style={{ maxWidth: '600px', margin: 'auto' }}>
                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        required
                    />
                </div>

                <div className="form-group">
                    {loading ? (
                        <button className="btn-default btn-large" style={{ width: '100%' }} disabled>Loading...</button>
                    ) : (
                        <button className="btn-default btn-large" style={{ width: '100%' }}>Sign In</button>
                    )}
                </div>
            </form>


            {showResult && resultMessage && (
                <p className={resultMessage.includes('successfully') ? 'success-message' : 'error-message'}>
                    {resultMessage}
                </p>
            )}
        </div>
    );
}


const mapStateToProps = (state) => {

  // Log the values
  console.log('Email from Redux:', state);


  // Return the mapped props
  return {
      user:state.user
  };
};

const mapDispatchToProps = (dispatch) => ({
    setUserDetails: (userDetails) => dispatch(setUserDetails(userDetails)),
    
});

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);
