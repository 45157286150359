// Nav.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useNav } from '../../context/NavContext';

const Nav = () => {
  
  const { selectedMenu, selectMenu } = useNav();


  const menuItems = {
    Home: '/',
    About: '/about-us',
    Jobs:'/jobs',
    // Recruitment: '/recuriment/candidate',
    Contact: '/contact',
    // Signin: '/signin',
    // Signup: '/signup',
  };

  const handleMenuClick = (menu) => {
    selectMenu(menu);
  };

  const navmenubgselect = {
    padding: '0 28px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'transparent',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    background: 'linear-gradient(to right, #1FA2FF 0%, #12D8FA  51%, #1FA2FF  100%) 95%/200% 100%',
    transitionDuration: '300ms',
    color: 'var(--color-white)',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    textDecoration: 'none',
    textShadow: 'var(--shadow-light)',
    display: 'inline-block',
    height: '50px',
    lineHeight: '47px',
    letterSpacing: '0.5px',
    marginTop:14,
    width: 'auto',
  };

  const navmenubgnotselect = {
    // ...navmenubgselect,
    borderColor: 'transparent !important',
    backgroundColor: 'transparent !important',
    transitionDuration: '300ms',
    color: 'var(--color-dark)',
  };

  return (
    <ul className="mainmenu">
      {Object.entries(menuItems).map(([menu, path]) => (
        <li  onClick={() => handleMenuClick(menu)}>
            <Link style={
            selectedMenu === menu
              ? navmenubgselect
              : navmenubgnotselect
          } to={path}>{menu === "About"? "Why Tactiss HR ?" : menu}</Link></li>
              
      ))}
    </ul>
  );
};

export default Nav;
