import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const TestimonialData =  {
        id: "01",
        form: "",
        description: ".",
        name: "",
        subtitle: "",
        image: "about2",
    }



const OurExpertises = ({column , teamStyle}) => {
    return (
        <div className="row">
            <div className={`${column}`}>
                <div className={`testimonial-style-two ${teamStyle}`}>
                    <div className="row align-items-center row--20">
                        <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                            <div className="content mt_sm--40">
                                <span className="form">{TestimonialData.form}</span>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora, ea at, laudantium nostrum minus pariatur quasi!</p> */}

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Recruitment</li>
                                        <li><span className="icon"><FiCheck /></span>Job Placement</li>
                                        <li><span className="icon"><FiCheck /></span> Background Verification (BGV)</li>
                                        <li><span className="icon"><FiCheck /></span> Training & Upskilling.</li>
                                        <li><span className="icon"><FiCheck /></span>HR Consultancy</li>
                                        <li><span className="icon"><FiCheck /></span>Technology</li>
                                        <li><span className="icon"><FiCheck /></span>Efficiency and Automation</li>
                                        <li><span className="icon"><FiCheck /></span>Enhanced Decision Making</li>
                                        <li><span className="icon"><FiCheck /></span>Improved Recruitment Process</li>
                                        <li><span className="icon"><FiCheck /></span>Compliance and Risk Management</li>
                                    </ul>
                                </ScrollAnimation>
                                <div className="client-info">
                                    <h4 className="title">{TestimonialData.name}</h4>
                                    <h6 className="subtitle">{TestimonialData.subtitle}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="order-1 order-md-2 col-lg-4 col-md-4">
                        <div className="">
                            <img className="w-100" src="./images/home/secondsection.png" alt="About Images" />
                        </div>
                        
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        
    )
}
export default OurExpertises;