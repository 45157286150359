import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import { BASE_URL } from '../../common/config/endpoints';

const JobDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobDetails = async () => {
      const job_id = new URLSearchParams(location.search).get('jobid');

      try {  
        const response = await fetch(`${BASE_URL}/job-details/${job_id}`);
        const data = await response.json();
        console.log(data,"data")
        setJobDetails(data.job);
      } catch (error) {
        console.error('Error fetching job details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [location.search]);

  const handleButtonClick = (role) => {
    history.push(`/recruitment?role=${role}`);
  };

  return (
    <>
    <SEO title={`Job Details || ${jobDetails?.job_title || 'Tactiss'}`} />
    <Layout>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="rwt-portfolio-details rn-section-gap">
          {jobDetails && (
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="inner">
                    <div className="details-list">
                      <div className="row mt--40 row--30">
                        <div className="col-lg-12 mt_md--30 mt_sm--30">
                          <div className="content-right">
                            <h5 className="subtitle">{jobDetails.job_title}</h5>
                            <div className="description" dangerouslySetInnerHTML={{ __html: jobDetails.description }} />
                            <div className="view-btn mt--50">
                              <button
                                type="submit"
                                className="btn-default btn-large round"
                                onClick={() => handleButtonClick('candidate')}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  </>
  );
};

export default JobDetails;
