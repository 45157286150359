// authActions.js
const localStorageKey = 'userDetails';

export const setUserDetails = (userDetails) => {
    // Save the user details to local storage
    localStorage.setItem(localStorageKey, JSON.stringify(userDetails));

    // Return the action object
    return {
        type: 'SET_USER_DETAILS',
        payload: userDetails,
    };
};
