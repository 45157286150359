import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "",
        title: "Our Team",
        description: "",
        image: "images/home/unveiling1.jpg",
        workingStep: [
            {
                stepTitle: "Empowering Your Workforce, Transforming Your Business:",
                stepDescription: "At Tactiss HR Services, we bring a fresh perspective to human resources, offering a range of comprehensive services designed to meet the evolving needs of your organization. With a commitment to excellence and a focus on personalized solutions, we are your trusted partner in talent acquisition, training, and HR consultancy.",
            },
        ]
    },
    {
        id: "2",
        date: "",
        title: "Why Tactiss HR ?",
        description: "Empowering Connections, Elevating Careers: Your Premier Talent Hub\nBuilding a better tomorrow.\nWhere Talent Meets Opportunity: Empowering Careers, Enriching Businesses.",
        image: "images/home/history.jpg",
        workingStep: [
            {
                stepTitle: "Empowering Your Workforce, Transforming Your Business:",
                stepDescription: "At Tactiss HR Services, we bring a fresh perspective to human resources, offering a range of comprehensive services designed to meet the evolving needs of your organization. With a commitment to excellence and a focus on personalized solutions, we are your trusted partner in talent acquisition, training, and HR consultancy.",
            },
        ]
    },
    {
        id: "3",
        date: "",
        title: "Why Choose us ?",
        description: "",
        image: "images/home/chooes.jpg",
        workingStep: [
            {
                stepTitle: 'Recruitment and Talent Acquisition',
                stepDescription: 'Discover the Right Talent for Your Success',
              },
              {
                stepTitle: 'Consultative Approach',
                stepDescription: 'Tailored Solutions for Your Unique Needs',
              },
              {
                stepTitle: 'Training and Development',
                stepDescription: 'Elevate Skills, Drive Growth',
              },
              {
                stepTitle: 'Background Verification',
                stepDescription: 'Ensuring Trust and Reliability',
              },
              {
                stepTitle: 'Diversity and Inclusion Strategies',
                stepDescription: 'Building Inclusive Workplaces',
              },
              {
                stepTitle: 'HR Consultancy',
                stepDescription: 'Optimizing Your HR Processes',
              },

        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-12 col-md-12 col-12" key={index}>
                                                    <div className="working-list" >
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title" style={{ marginBottom: '4px' }}>{data.stepTitle} :</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="read-morebtn">
                                            {/* <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round" href="/contact"><span>Get Started Now</span></a>
                                            </ScrollAnimation> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`${data.image}`} alt="Tactiss" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
