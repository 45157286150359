// contentData.js

export const recruitmentContent = `
Streamlining Your Hiring Success.
In the dynamic landscape of talent acquisition, Tactiss Services emerges as your strategic partner for seamless and efficient hiring. Leveraging cutting-edge technology and a personalized approach, Tactiss ensures a tailored recruitment process that aligns with your organization's unique needs.
Experience the ease of recruitment with Tactiss Services – where expertise meets innovation, and your workforce dreams become a reality. Elevate your hiring game with Tactiss, your trusted ally in talent acquisition.
`;

export const candidateContent = `
Navigating Careers, Connecting Talent, Creating Success.
Embarking on a career journey is a pivotal moment, and finding the right opportunities is crucial. "Tactiss Services" stands out as a beacon in the realm of job placement, dedicated to connecting skilled individuals with their ideal roles. With a proven track record of success, Tactiss Services goes beyond conventional placement agencies, fostering lasting partnerships between employers and candidates. Their commitment to precision in matching skills and culture ensures a seamless fit for both parties. Joining forces with Tactiss Services opens doors to a world of possibilities, where talent meets opportunity. Let Tactiss Services guide you on the path to professional fulfillment and success. Your dream career awaits – seize it with Tactiss.
`;

export const trainingContent = `
Bridging Excellence in Recruitment and Talent Development.
At Tactiss Services, we take pride in shaping the future of both candidates and recruiters. Our commitment extends beyond conventional hiring practices. For candidates, we provide tailored training programs that not only enhance their skills but also nurture their professional growth. Simultaneously, we empower recruiters with cutting-edge strategies, ensuring they identify and secure the best-fit talent.
With a focus on holistic development, Tactiss fosters a symbiotic relationship between candidates and recruiters. Our innovative approach transforms challenges into opportunities, creating a dynamic workforce prepared for the demands of the evolving business landscape. Tactiss Services stands as a beacon of excellence, propelling individuals and organizations towards unparalleled success in the realm of talent acquisition and development.
`;
