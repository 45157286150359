import React from 'react';
import Typed from 'react-typed';

const About = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="/images/about/about-1.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title"> <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Welcome to TACTISS  SERVICES",
                                            // "Finance.",
                                            // "Agency.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Company Overview:</p>
                                <p>Tactiss has won accolades and business from existing and new clients for our consultative recruitment approach. We have been helping our clients to improve their recruitment process in terms of Quality-of-Hire, Time-to-Fill and Cost-per-Hire. We specialize in providing customized hiring solutions to our clients so that they can achieve greater efficiency and business performance. Through our collaborative approach and flawless execution, we help our clients achieve their strategic talent acquisition goals. Our passion, integrity and work ethics set us apart and make us a preferred hiring partner with our ever-expanding client base. We seek to deliver excellent value to our clients and candidates alike with our highly professional services. We are proud to call ourselves recruiter… to build the better tomorrow.</p>

                                <p>Our Values:</p>
                                <p>Philosophy: We believe that recruitment is more than helping candidates change jobs and filling open positions for an employer. To us, it is about empowerment. Empowering candidates with sound career choices and building strong, stable and productive teams for an organization.</p>

                                <p>Our Mission:</p>
                                <p>To help candidates realize their true potential and career aspirations, at the same time help employers recruit the best available talent and implement sound and effective HR practices.</p>

                                <p>Our Vision:</p>
                                <p>To be the best-in-class recruitment and to build the better tomorrow.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
