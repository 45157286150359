import React, { useState, useEffect } from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";

const ContactOne = () => {
  const [contactDetails, setContactDetails] = useState({
    phoneNumbers: [],
    emailAddresses: [],
    location: "",
  });

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://tactissadmin.frontendsourcecode.com/api/v1/home")
      .then((response) => response.json())
      .then((data) => {
        // Update contact details with the fetched data
        const { latestJobs } = data.data;
        if (latestJobs && latestJobs.length > 0) {
          const { company } = latestJobs[0];
          setContactDetails({
            phoneNumbers: [
              company.user.phone, // Assuming phone number is present in the user object
            ],
            emailAddresses: [company.user.email],
            location: company.location,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              {contactDetails.phoneNumbers.length > 0 && (
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <FiHeadphones />
                    </div>
                    <div className="inner">
                      <h4 className="title">Contact Phone Number</h4>
                      +91-6364864718
                    </div>
                  </div>
                </div>
              )}

              {contactDetails.emailAddresses.length > 0 && (
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMail />
                    </div>
                    <div className="inner">
                      <h4 className="title">Our Email Address</h4>
                      contact@tactiss.in
                    </div>
                  </div>
                </div>
              )}

              {contactDetails.location && (
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMapPin />
                    </div>
                    <div className="inner">
                      <h4 className="title">Our Location</h4>
                      <p>Salapuria Sattava 4th Floor Bommanahalli Bangalore 560068</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        {/* <div className="col-lg-7">

          <ContactForm
            formStyle="contact-form-1"
            phoneNumbers={contactDetails.phoneNumbers}
            emailAddresses={contactDetails.emailAddresses}
            location={contactDetails.location}
          />
        </div> */}
        <div className="col-lg-12 mt_md--30 mt_sm--30">
          <GoogleMapStyle />
        </div>
      </div>
    </>
  );
};

export default ContactOne;
