import React from 'react';
import { BASE_URL } from '../../../common/config/endpoints';


const SideCategories = ({ onCategoryClick }) => {


  const [jobsData, setJobsData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {      
        const response = await fetch(`${BASE_URL}/home`);
        const data = await response.json();

        setJobsData(data.data.jobCategories);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleCategoryClick = (categoryId) => {

    onCategoryClick(categoryId);
  };

  return (
    <ul className="category-list ">
      <li style={{ cursor: "pointer" }}>
        <a onClick={() => handleCategoryClick("all")}>
          <span className="left-content">{"All"}</span>
          <span className="count-text">{" "}</span>
        </a>

      </li>
      {jobsData.map((cat, index) => {
        if (cat.jobs_count > 0) {
          return (
            <li key={index} style={{ cursor: "pointer" }}>
              <a onClick={() => handleCategoryClick(cat.id)}>
                <span className="left-content">{cat.name}</span>
                <span className="count-text">{cat.jobs_count}</span>
              </a>
            </li>
          );
        } else {
          // Handle the case where jobs_count is not greater than 0 (optional)
          return null;
        }
      })}
    </ul>
  )
}

export default SideCategories
