import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import { useLocation } from 'react-router-dom';

import SignInForm from './SignInForm';
import RequirmentForm from './RequirmentForm';
import { recruitmentContent, candidateContent, trainingContent } from './contentData';


const Requirment = () => {
    const location = useLocation();

    const role = new URLSearchParams(location.search).get('role');


    const getContentBasedOnRole = (role) => {
        switch (role) {
          case 'recruiter':
            return {
              heading: 'Recruitment',
              description: recruitmentContent,
            };
          case 'candidate':
            return {
              heading: 'Candidate',
              description: candidateContent,
            };
          case 'training':
            return {
              heading: 'Training',
              description: trainingContent,
            };
          default:
            return {
              heading: 'Tactiss',
              description: 'Default Description',
            };
        }
      };


      const { heading, description } = getContentBasedOnRole(role);

    return (
        <>
            <SEO title="Contact || Tactiss" />
            <Layout>
                <div className="slider-area slider-style-1  bg_image"  >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--10 text-center">
                                    <div style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}>
                                        {/* <h3 className="rn-sub-badge"><span className="theme-gradient">{heading}</span></h3> */}
                                        <h1 className="title theme-gradient h2"  dangerouslySetInnerHTML={{ __html: heading }}></h1>
                                        <p style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column",textAlign:"left"}}>{description}</p>
                                    </div>
                                    {/* <h1 className="title display-one">Company Overview</h1> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area ">
                        <div className="container">

                            <RequirmentForm role={role} />

                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Requirment;