// JobService.jsx
import React, { useState, useEffect } from 'react';
import { FiActivity } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { BASE_URL } from '../../common/config/endpoints';

const truncateText = (text, linesCount) => {
  const lines = text.split('\n');
  if (lines.length > linesCount) {
    return lines.slice(0, linesCount).join('\n') + '...';
  }
  return text;
};

const JobService = ({ textAlign, serviceStyle, jobid,searchQuery }) => {
  console.log(jobid, "jobid");
  const [jobsData, setJobsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/jobs`);
        const data = await response.json();

        // Filter jobs based on jobid
        const filteredJobs = jobid === "all" ? data.data.data : data.data.data.filter((job) => job.job_category_id === jobid);

        // Filter jobs based on search query
        const searchedJobs = filteredJobs.filter((job) =>
          job.job_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          job.description.toLowerCase().includes(searchQuery.toLowerCase())
        );

        setJobsData(searchedJobs);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, [jobid, searchQuery]); // Add jobid as a dependency

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleButtonClick = (jobid) => {
    history.push(`/jobdetails?jobid=${jobid}`);
  };

  return (
    
    <div className="row row--15 service-wrapper">
      {jobsData.length > 0 &&
        jobsData.map((val, i) => (
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5" key={i}>
          
            {/* <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}> */}
            <div  className={`service card ${serviceStyle} ${textAlign}`} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div>
                <div className="icon">
                  <FiActivity />
                </div>
                <div className="content" style={{ flex: '1', overflow: 'hidden' }}>
                  <h4 className="title w-600">
                    <Link to={`/jobdetails/${val.job_id}`} dangerouslySetInnerHTML={{ __html: val.job_title }}></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    style={{ overflow: 'hidden', lineHeight: '1.2em', maxHeight: '3em' }}
                    dangerouslySetInnerHTML={{ __html: truncateText(val.description, 1) }}
                  ></p>
                </div>
                <div className="view-btn mt--50" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    className="btn-default btn-large round"
                    type="submit"
                    style={{ height: 39, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    onClick={() => handleButtonClick(val.job_id)}
                  >
                    Details
                  </button>
                </div>
              </div>
              </div>
            {/* </ScrollAnimation> */}
          </div>
        ))}
    </div>
  );
};

export default JobService;
