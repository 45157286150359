import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

const Intro = () => {
  const history = useHistory();

  const heroStyles = {
    width: '100%',
    backgroundImage: 'url("./images/bg/hero-bg1.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'right top',
    marginTop: '70px',
    padding: '60px 0',
  };

  const h1Styles = {
    margin: '0 0 20px 0',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '56px',
    color: 'green',
    textShadow: '2px 2px 2px lightgray',
  };

  const h2Styles = {
    color: '#576971',
    marginBottom: '30px',
    fontSize: '20px',
    fontFamily: "Comfortaa, sans-serif",
    fontWeight: 600
  };

  const h2Styles1 = {
    color: '#576971',
    marginBottom: '0px',
    fontSize: '20px',
    fontFamily: "Comfortaa, sans-serif",
    fontWeight: 600
  };


  const heroImgStyles = {
    textAlign: 'center',
  };

  const imgStyles = {
    width: '100%',
  };

  const handleButtonClick = (role) => {
    history.push(`/recruitment?role=${role}`);
  };

  return (
    <section className="hero" style={heroStyles}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="fade-up">
            <div>
              <h1 style={{
                fontSize: 35,
                fontFamily: "Comfortaa, sans-serif", fontWeight: "bolder"
              }} data-aos="fade-up" className="aos-init aos-animate">
                Recruit Brighter Minds and Streamline Onboarding.
              </h1>

              <h2 style={h2Styles1}>Training + Certification
              </h2>
              <h2 style={h2Styles}>Job Placement + HR Consultancy</h2>
              <div className="button-group">
                <button className="btn-default btn-medium round btn-icon" onClick={() => handleButtonClick('recruiter')}>
                  Recruiter
                </button>
                <button className="btn-default btn-medium round btn-icon" onClick={() => handleButtonClick('candidate')}>
                  Candidate
                </button>
                <button className="btn-default btn-medium round btn-icon" onClick={() => handleButtonClick('training')}>
                  Training
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left" style={heroImgStyles}>
            <img src="./images/home/home.png" className="img-fluid" alt="" style={imgStyles} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
