import React from 'react';
import { FaSistrix } from "react-icons/fa";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SideCategories from './sidebar/SideCategories';

// import ServiceOne from "./ServiceOne";
import JobService from './JobService';

const Jobs = () => {
    const [jobid, setJobId] = React.useState("");
    const [searchQuery, setSearchQuery] = React.useState('');

    const onCategoryClick = (id) => {
        setJobId(id)
    }


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    return (
        <>
            <SEO title="Openings || Tactiss" />

            <Layout>
         
                <div className="main-content">
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row row--30">
                                <div className="col-lg-4 mt_md--40 mt_sm--40">
                                    <aside className="rwt-sidebar">

                                        <div className="rbt-single-widget widget_search mt--40">
                                            <div className="inner">
                                                <form className="blog-search" action="#">
                                                    <input type="text" placeholder="Search ..." value={searchQuery} onChange={handleSearchChange} />
                                                    <button className="search-button">
                                                        <FaSistrix />
                                                    </button>
                                                </form>
                                            </div>
                                        </div>


                                        {/* Start Single Widget  */}
                                        <div className="rbt-single-widget widget_categories mt--40">
                                            <h3 className="title">Categories</h3>
                                            <div className="inner">
                                                <SideCategories onCategoryClick={onCategoryClick} />
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}




                                    </aside>
                                </div>


                                <div className="col-lg-8">
                                    <div className="row mt_dec--30">
                                        {/* Start Service Area  */}
                                        <div className="rn-service-area rn-section-gap">
                                            <div className="container">

                                                <JobService
                                                    serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                                                    textAlign="text-center"
                                                    jobid={jobid || "all"}
                                                    searchQuery={searchQuery}
                                                />
                                            </div>
                                        </div>
                                        {/* End Service Area  */}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>

            </Layout>
        </>
    )
}

export default Jobs
