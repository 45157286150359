import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const programList = [
    'ISO 9001:2015 CERTIFICATION',
    'EXPERIENCED FACULTY',
    'STUDY MATERIAL',
    'WORKSHOPS / WEBINARS (Resume Writing, LinkedIn Mastering & Email Etiquette)',
    '100% PLACEMENT ASSISTANCE',
    'JOB READINESS',
];

const objectives = [
    'Make a successful career in the field of facilitation and training.',
    'Successfully apply the adult learning principles in training.',
    'Professionally and effectively deal and train trainees with different learning and personality styles.',
    'Make a positive impact on trainees and organizations.',
    'Play a suitable trainer’s role in different training situations.',
    'Manage group dynamics in training and corporate contexts.',
    'Evaluate training programs.',
    'Realize the importance of managing each phase of group dynamics.',
];


const SlipThree = () => {
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">

                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/home/fourthsec.jpg" alt="split Images" />
                        </div>
                    </div>

                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner" style={{
                            padding: '33px 40px',
                        }}>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h4 className="title">Tactiss HR Training Services:</h4>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="description">To enable HR Practical Training and Job Focused Knowledge</p>

                                <p>  <ul>
                                    {programList.map((item, index) => (
                                        <li key={index}><p style={{margin:"0 0 3px",fontSize:13}}>{item}</p></li>
                                    ))}
                                </ul>
                                </p>

                                <h5>
                                    By the successful completion of the program, participants will be able to:

                                </h5>

                                <div>
                                    {objectives.map((objective, index) => (
                                        <p style={{margin:"0 0 3px"}} key={index}>{objective}</p>
                                    ))}
                                </div>


                            </ScrollAnimation>


                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}
export default SlipThree;