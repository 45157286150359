import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";

import OTPForm from './OtpForm';

const OTP = () => {
    return (
        <>
            <SEO title="OTP || Tactiss" />
            <Layout>
          
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner text-center">
                            <h1 className="title theme-gradient h2" dangerouslySetInnerHTML={{__html: "OTP"}}></h1>
                        </div>
                    </div>
                </div>
           
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area ">
                        <div className="container">
                        
                            <OTPForm />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default OTP;