import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const OtpForm = ({ email,  user }) => {
    const [otp, setOtp] = useState('');
    const [result, setResult] = useState({ success: false, message: '' });
    const [timer, setTimer] = useState(60);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const history = useHistory();

    const handleChange = (e) => {
        const numericValue = e.target.value.replace(/\D/g, '');
        setOtp(numericValue);
    };

    useEffect(() => {
        if (!user || !user.data || !user.data.token) {
            history.push('/');
        }
    }, [user, history]);

    const handleOtpSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://tactissadmin.frontendsourcecode.com/api/v1/register/activate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${user.data.token_type} ${user.data.token}`,
                },
                body: JSON.stringify({
                    email: email,
                    otp: otp,
                }),
            });

            const responseData = await response.json();

            setResult({
                success: response.success && otp === user.data.user.otp,
                message: responseData.message,
            });

            // Reset timer when OTP is submitted successfully
            if (response.success && otp === user.data.user.otp) {
                resetTimer();
            }
        } catch (error) {
            console.error('Error during API call:', error);
            setResult({
                success: false,
                message: 'Failed to activate account. Please try again.',
            });
        }
    };

    const handleResendClick = async () => {
        try {
            const response = await fetch('http://127.0.0.1:8000/api/v1/resend-verification', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${user.token_type} ${user.data.token}`,
                },
                body: JSON.stringify({
                    email: email,
                }),
            });

            const responseData = await response.json();

            if (response.ok) {
                // Resend successful, reset timer
                resetTimer();
            } else {
                console.error('Failed to resend OTP:', responseData.message);
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };

    const resetTimer = () => {
        setTimer(60);
        setIsResendDisabled(true);

        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 1) {
                    clearInterval(countdown);
                    setIsResendDisabled(false);
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    // Effect to reset the timer on component mount
    useEffect(() => {
        resetTimer();
    }, []);

    return (
        <form className={`container`} action="" onSubmit={handleOtpSubmit} style={{ maxWidth: '600px', margin: 'auto' }}>
            <div className="form-group">
                <input
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="form-group">
               <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <button className="btn-default btn-large" >Submit OTP</button>
                    {isResendDisabled ? `Resend OTP in ${timer}s` : <button
                        className="btn-default btn-large"
                        onClick={handleResendClick}
                        disabled={isResendDisabled}
                    >
                        Resend OTP
                    </button>}
                </div>
            </div>

            <div className="form-group">
                {result.message && (
                    <p style={{ color: result.success ? 'green' : 'red' }}>{result.message}</p>
                )}
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(OtpForm);
